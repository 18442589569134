<template>
    <div>
        <b-container>
            <b-form class="mt-3 w-100" @submit.prevent="onSubmit">
                <div class="w-100 px-2">
                    <b-row class="pt-2 mb-2">
                        <label for="mainImage" class="form-label text-start">Profile Picture<span style="color:red"> *</span></label>
                    </b-row>
                    <input type="file" class="form-control" id="mainImage" @change="profileChange($event.target.files)">
                </div>
                <b-form-group id="info-input" class="mt-4 px-2">
                <b-row>
                    <label for="input-1" class="form-label text-start">Username<span style="color:red"> *</span></label>
                </b-row>
                <b-form-input
                    id="input-1"
                    v-model="form.username"
                    type="text"
                    placeholder="e.g. parsilluser123"
                    size="lg"
                    autocomplete="off"
                    aria-describedby="input-1-live-feedback"
                >
                </b-form-input>
                <b-row>
                    <label for="input-2" class="form-label text-start mt-3">Age<span style="color:red"> *</span></label>
                </b-row>
                <b-form-input
                    id="input-2"
                    v-model="form.age"
                    type="number"
                    size="lg"
                    autocomplete="off"
                >
                </b-form-input>
                <b-row>
                    <label for="input-3" class="form-label text-start mt-3">City/Town<span style="color:red"> *</span></label>
                </b-row>
                <b-form-input
                    id="input-3"
                    v-model="form.city"
                    type="text"
                    size="lg"
                    autocomplete="off"
                >
                </b-form-input>
                <b-row>
                    <label for="input-5" class="form-label text-start mt-3">State<span style="color:red"> *</span></label>
                </b-row>
                <b-form-input
                    id="input-5"
                    v-model="form.state"
                    type="text"
                    size="lg"
                    autocomplete="off"
                >
                </b-form-input>
                <div class="title-label-row align-items-center">
                    <label for="input-4" class="form-label text-start mt-3">Bio<span style="color:red"> *</span></label>

                    <span>{{bioLength}}/{{bioMax}}</span>
                </div>
                <b-form-textarea
                    id="input-4"
                    v-model="form.bio"
                    type="text"
                    size="lg"
                    autocomplete="off"
                    max-rows="3"
                >
                </b-form-textarea>
            </b-form-group>
            <b-spinner v-if="loading==true" class="mt-5 mb-3"></b-spinner>
            <b-button v-else class="mt-5 mb-3" variant="primary" size="lg" @click="completeProfile">Save Profile</b-button>
            </b-form>
        </b-container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { db, storage } from '../../db';

export default {
    data() {
        return {
            bioMax: 100,
            form: {
                image: null,
                username: null,
                age: null,
                city: null,
                state: null,
                bio: "",
            },
            loading: false,
        }
    },
    watch: {
        bio(newForm, oldForm) {
            const newValue = newForm.bio;
            const oldValue = oldForm.bio;
            if (newValue.length > this.bioMax) {
                this.$nextTick(() => {
                    this.form.bio = oldValue;
                });
            }
            else {
                this.form.bio = newValue;
            }
        }
    },
    created() {
        this.form.username = this.parsillUser.username;
        this.form.age = this.parsillUser.age;
        this.form.city = this.parsillUser.city;
        this.form.state = this.parsillUser.state;
        this.form.bio = this.parsillUser.bio;
    },
    methods: {
        profileChange(files) {
            if (files.length > 0) {
                this.form.image = files[0];

            }
        },
        completeProfile() {

            var completedProfile = true;

            if (this.form.image == null && this.parsillUser.profileComplete == false) completedProfile = false;
            if (this.form.username == null || this.form.username == "") completedProfile = false;
            if (this.form.age == null || this.form.username == "") completedProfile = false;
            if (this.form.city == null || this.form.username == "") completedProfile = false;
            if (this.form.state == null || this.form.username == "") completedProfile = false;
            if (this.form.bio == null || this.form.username == "") completedProfile = false;
            

            this.loading = true;
            
            var data = {
                username: this.form.username,
                age: this.form.age,
                city: this.form.city,
                state: this.form.state,
                bio: this.form.bio,
                profileComplete: completedProfile,
            };
            
            db.collection("users").doc(this.parsillUser.id).update(data)
            .then(() => {
                if (this.form.image != null) {
                    // upload profile picture
                    var profileRef = storage.ref().child("users/" + this.parsillUser.id + "/profile");
                    profileRef.put(this.form.image)
                    .then(() => {
                        this.$store.commit("updateProfilePicture");
                        this.$router.go(-1);
                    })
                    .catch(err => {
                        alert("Error uploading profile picture: " + `${err}`);
                        this.loading = false;
                        return;
                    });
                } else {
                    this.$router.go(-1);
                }
            })
            .catch(err => {
                alert("Error updating profile info: " + `${err}`);
                this.loading = false;
                return;
            });
        }
    },
    computed: {
        ...mapState({
            parsillUser: (state) => state.parsillUser,
        }),
        bioLength() {
            return this.form.bio?.length ?? 0;
        }
    }
}
</script>

<style scoped>
    .btn-primary {
        background-color: #09256e !important;
    }

    .form-label {
        font-size: larger;
    }

    .title-label-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>