<template>
    <div class="no-overflow">
        <AppbarFixed v-if="parsillUser.profileComplete != true" :hasBackButton="true" title="Complete Profile"/>
        <AppbarFixed v-else :hasBackButton="true" title="Edit Profile"/>
        <CompleteProfileForm/>
        <div class="bottom-margin-box"></div>
        <BottomNavBar :currentIndex="1" @updateIndex="updateIndex"/>
    </div>
</template>

<script>
import AppbarFixed from '../../components/general/AppbarFixed.vue';
import CompleteProfileForm from '../../components/complete_profile/CompleteProfileForm.vue';
import BottomNavBar from '../../components/general/BottomNavBar.vue';

import { mapState } from 'vuex';

export default {
    components: {
        AppbarFixed,
        CompleteProfileForm,
        BottomNavBar,
    },
    computed: {
        ...mapState({
            parsillUser: (state) => state.parsillUser,
        })
    },
    methods: {
        updateIndex(index) {
            if (index == 4) {
                // means its create project
                this.$router.push("/create/project");
                index = 1;
            } else {
                this.$router.push("/");
            }
            this.$store.commit("updateTabIndex", index);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>